exports.components = {
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-news-markdown-remark-fields-slug-js": () => import("./../../../src/pages/news{markdownRemark.fields__slug}.js" /* webpackChunkName: "component---src-pages-news-markdown-remark-fields-slug-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-music-music-js": () => import("./../../../src/templates/music/music.js" /* webpackChunkName: "component---src-templates-music-music-js" */),
  "component---src-templates-news-collections-news-js": () => import("./../../../src/templates/news/collections/news.js" /* webpackChunkName: "component---src-templates-news-collections-news-js" */),
  "component---src-templates-news-news-js": () => import("./../../../src/templates/news/news.js" /* webpackChunkName: "component---src-templates-news-news-js" */)
}

